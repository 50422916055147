// src/components/Header.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useScrollDirection from '../hooks/useScrollDirection';

function Header() {
  const { t, i18n } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const scrollDir = useScrollDirection(50); // Adjust threshold as needed

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsMenuOpen(false);
  };

  return (
    <header
      className={`fixed top-0 left-0 w-full bg-primary text-white py-4 transition-transform duration-300 ${
        scrollDir === 'down' ? '-translate-y-full' : 'translate-y-0'
      } z-50`}
    >
    <div className="container mx-auto px-4 flex justify-between items-center">
        <div className="w-1/3"></div>
        <div className="w-1/3 flex justify-center">
          <Link to="/" className="font-bold text-2xl glow-text transform hover:scale-125 transition-transform duration-200 whitespace-nowrap">
            STUDIO ARTEMIS
          </Link>
        </div>
        <div className="w-1/3 flex justify-end items-center">
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none flex items-center"
            aria-label="Toggle Menu"
          >
            <span className="mr-2 text-sm">MENU</span>
            <svg
              className="h-6 w-6 fill-current"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              {isMenuOpen ? (
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                />
              ) : (
                <path
                  fillRule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                />
              )}
            </svg>
          </button>
        </div>
      </div>

      {/* Menu overlay */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-100 z-40 ${
          isMenuOpen ? 'block' : 'hidden'
        }`}
        onClick={toggleMenu}
        aria-hidden="true"
      ></div>

      {/* Side menu */}
      <nav
        className={`fixed top-0 right-0 bottom-0 w-64 bg-primary text-white z-50 transform ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        } transition-transform duration-300 ease-in-out`}
        aria-label="Side Navigation"
      >
        <div className="p-4">
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none float-right"
            aria-label="Close Menu"
          >
            <svg
              className="h-6 w-6 fill-current"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
              />
            </svg>
          </button>
        </div>
        <div className="flex justify-center space-x-2 py-4 border-b border-gray-700">
          <button
            onClick={() => changeLanguage('en')}
            className="hover:text-accent focus:outline-none"
          >
            EN
          </button>
          <span>|</span>
          <button
            onClick={() => changeLanguage('ko')}
            className="hover:text-accent focus:outline-none"
          >
            KR
          </button>
          <span>|</span>
          <button
            onClick={() => changeLanguage('id')}
            className="hover:text-accent focus:outline-none"
          >
            ID
          </button>
        </div>
        <ul className="font-body flex flex-col space-y-2 p-4">
          <li>
            <Link
              to="/about"
              className="block py-2 hover:text-accent hover:bg-primary transform hover:scale-110 transition-transform duration-200"
              onClick={toggleMenu}
            >
              {t('about')}
            </Link>
          </li>
          <li>
            <Link
              to="/current-works"
              className="block py-2 hover:text-accent hover:bg-primary transform hover:scale-110 transition-transform duration-200"
              onClick={toggleMenu}
            >
              {t('currentWorks')}
            </Link>
          </li>
          <li>
            <Link
              to="/ip-management"
              className="block py-2 hover:text-accent hover:bg-primary transform hover:scale-110 transition-transform duration-200"
              onClick={toggleMenu}
            >
              {t('ipManagement')}
            </Link>
          </li>
          <li>
            <Link
              to="/other-services"
              className="block py-2 hover:text-accent hover:bg-primary transform hover:scale-110 transition-transform duration-200"
              onClick={toggleMenu}
            >
              {t('otherServices')}
            </Link>
          </li>
          <li>
            <Link
              to="/contact-us"
              className="block py-2 hover:text-accent hover:bg-primary transform hover:scale-110 transition-transform duration-200"
              onClick={toggleMenu}
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
