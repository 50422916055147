import React, { useState } from 'react';
import CWMovieModal from './CWMovieModal';
import defaultImage from '../assets/images/currentWorks/default.png';

const MovieCard = ({ movie }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { title, image } = movie;

  // Dynamically import the image
  let imageSrc;
  try {
    imageSrc = require(`../assets/images/currentWorks/${image}`);
  } catch (err) {
    imageSrc = defaultImage;
  }

  return (
    <>
      <div
        className="relative cursor-pointer group"
        onClick={() => setModalIsOpen(true)}
      >
        <div className="w-full rounded-lg overflow-hidden shadow-md">
          <div className="relative pb-[150%]">
            <img
              src={imageSrc}
              alt={title}
              className="absolute inset-0 w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-60 transition-opacity duration-300 flex items-center justify-center">
              <h3 className="text-white text-3xl font-subheader opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                {title}
              </h3>
            </div>
          </div>
        </div>
      </div>

      <CWMovieModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        movie={movie}
      />
    </>
  );
};

export default MovieCard;
