import React from 'react';
import { useTranslation } from 'react-i18next';

function IPManagement() {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="font-header text-4xl font-bold mb-4">{t('ipManagementTitle')}</h1>
      <p className="font-body text-lg mb-4">{t('ipManagementDescription')}</p>
      {/* Add more content here */}
    </div>
  );
}

export default IPManagement;