// src/utils/preloadImages.js

// Preload frame images
export const preloadImages = (count, path, prefix, extension) => {
  const promises = [];

  for (let i = 1; i <= count; i++) {
    const img = new Image();
    img.src = `${path}/${prefix}${i}.${extension}`;
    const promise = new Promise((resolve, reject) => {
      img.onload = () => resolve(img);
      img.onerror = () => reject(new Error(`Failed to load image: ${img.src}`));
    });
    promises.push(promise);
  }

  return Promise.all(promises);
};

// Preload flying images
export const preloadFlyingImages = (imageNames, path, extension) => {
  const promises = imageNames.map((name) => {
    const img = new Image();
    img.src = `${path}/${name}.${extension}`;
    return new Promise((resolve, reject) => {
      img.onload = () => resolve(img);
      img.onerror = () => reject(new Error(`Failed to load image: ${img.src}`));
    });
  });

  return Promise.all(promises);
};
