// src/components/FilmCard.js

import React from 'react';

function FilmCard({ film, openModal }) {
  const { title, poster } = film;

  return (
    <div
      className="relative cursor-pointer"
      onClick={() => openModal(film)}
    >
      <img src={poster} alt={title} className="w-full h-auto rounded-lg" />
      <div className="absolute inset-0 bg-black bg-opacity-70 opacity-0 hover:opacity-100 flex items-center justify-center transition-opacity duration-300">
        <h3 className="text-text font-subheader text-2xl">{title}</h3>
      </div>
    </div>
  );
}

export default FilmCard;
