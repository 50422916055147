// src/pages/ContactUs.js

import React from 'react';
import logo from '../assets/artemis_logo_new.png'; // Corrected import path

function ContactUs() {
  return (
    <div className="bg-background text-text">
      {/* Header Section */}
      <section className="container mx-auto px-4 py-12 text-center">
        <h1 className="font-header text-4xl md:text-6xl font-bold mb-4">
          Studio Artemis
        </h1>
        <h2 className="font-header text-3xl md:text-5xl font-bold mb-4">
          스튜디오 아르테미스
        </h2>
        <p className="font-body text-lg md:text-xl">
          Global Production House & IP Management
        </p>
      </section>


      {/* Address Section */}
      <section className="container mx-auto px-4 py-8 text-center">
        <img
          src={logo}
          alt="Studio Artemis Logo"
          className="mx-auto h-28 md:h-36 lg:h-48 w-auto object-contain transform hover:scale-110 transition-transform duration-300"
        />
        <h3 className="font-subheader text-2xl font-bold mb-4">Our Address</h3>
        <p className="font-body text-lg mb-2">
          23, Samseongyo-ro 18-gil, Seongbuk-gu, Seoul, Korea, 136-043
        </p>
        <p className="font-body text-lg">서울특별시 강남구 강남대로 1234</p>
        <br /><br />
        <h3 className="font-subheader text-2xl font-bold mb-4">Email Us Here:</h3>
        <p className="font-body text-lg mb-2">
          contact@studioartemis.net
        </p>
      </section>

      {/* Google Maps Section */}
      <section className="container mx-auto px-4 py-8">
        <h3 className="font-subheader text-2xl font-bold mb-4 text-center">
          Find Us Here
        </h3>
        <div className="flex justify-center">
          <iframe
            title="Studio Artemis Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6887.419268099808!2d127.00766008294138!3d37.5893261187476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357cbccf32a08871%3A0xf6095e5909de0fbe!2s23%20Samseongyo-ro%2018-gil%2C%20Seongbuk%20District%2C%20Seoul!5e0!3m2!1sen!2skr!4v1728935772523!5m2!1sen!2skr"
            width="100%"
            height="450"
            allowFullScreen=""
            loading="lazy"
            className="rounded-lg shadow-lg"
          ></iframe>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="container mx-auto px-4 py-8 text-center">
        <h3 className="font-header text-4xl font-bold mb-1">
          We’d Love to Hear From You!
        </h3>
      </section>

      {/* Contact Form Section */}
      <section className="container mx-auto px-4 py-8">
        <div className="max-w-2xl mx-auto">
          <form className="space-y-6">
            <div>
              <label className="block font-body text-lg mb-2" htmlFor="name">
                Name
              </label>
              <input
                className="w-full p-3 rounded-lg bg-primary text-text outline outline-secondary focus:outline-none focus:ring-2 focus:ring-accent"
                type="text"
                id="name"
                name="name"
                required
              />
            </div>
            <div>
              <label className="block font-body text-lg mb-2" htmlFor="email">
                Email
              </label>
              <input
                className="w-full p-3 rounded-lg bg-primary text-text outline outline-secondary focus:outline-none focus:ring-2 focus:ring-accent"
                type="email"
                id="email"
                name="email"
                required
              />
            </div>
            <div>
              <label className="block font-body text-lg mb-2" htmlFor="message">
                Message
              </label>
              <textarea
                className="w-full p-3 rounded-lg bg-primary text-text outline outline-secondary focus:outline-none focus:ring-2 focus:ring-accent"
                id="message"
                name="message"
                rows="5"
                required
              ></textarea>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="px-6 py-3 bg-text text-primary font-bold rounded-lg hover:bg-accent transition-colors duration-300"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </section>

      {/* Social Media Section */}
      <section className="container mx-auto px-4 py-8 text-center">
        <h3 className="font-subheader text-2xl font-bold mb-4">Connect With Us</h3>
        <div className="flex justify-center space-x-6">
          <a
            href="https://instagram.com/yourprofile"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* Instagram Icon */}
            <svg
              className="w-8 h-8 fill-current text-text hover:text-secondary transition-colors duration-300"
              viewBox="0 0 24 24"
            >
              {/* Instagram SVG Path */}
              <path
                d="M12 2.163c3.204 0 3.584.012 4.85.07 1.366.062 2.633.333 3.608 1.308.975.976 1.246 2.243 1.308 3.609.058 1.266.07 1.646.07 4.85s-.012 3.584-.07 4.85c-.062 1.366-.333 2.633-1.308 3.608-.976.975-2.243 1.246-3.609 1.308-1.266.058-1.646.07-4.85.07s-3.584-.012-4.85-.07c-1.366-.062-2.633-.333-3.608-1.308-.975-.976-1.246-2.243-1.308-3.609C2.175 15.747 2.163 15.367 2.163 12s.012-3.584.07-4.85c.062-1.366.333-2.633 1.308-3.608.976-.975 2.243-1.246 3.609-1.308C8.416 2.175 8.796 2.163 12 2.163zm0-2.163C8.741 0 8.332.013 7.052.072 5.771.131 4.687.407 3.759 1.336c-.93.928-1.205 2.012-1.264 3.293C2.013 5.668 2 6.077 2 9.335v5.33c0 3.258.013 3.667.072 4.948.059 1.281.334 2.365 1.264 3.293.928.93 2.012 1.205 3.293 1.264 1.28.059 1.689.072 4.948.072s3.667-.013 4.948-.072c1.281-.059 2.365-.334 3.293-1.264.93-.928 1.205-2.012 1.264-3.293.059-1.281.072-1.69.072-4.948v-5.33c0-3.258-.013-3.667-.072-4.948-.059-1.281-.334-2.365-1.264-3.293-.928-.93-2.012-1.205-3.293-1.264C15.667.013 15.258 0 12 0z"
              />
              <circle cx="12" cy="12" r="3.5" />
              <circle cx="18.406" cy="5.595" r="1.439" />
            </svg>
          </a>
          <a
            href="https://youtube.com/yourchannel"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* YouTube Icon */}
            <svg
              className="w-8 h-8 fill-current text-text hover:text-secondary transition-colors duration-300"
              viewBox="0 0 24 24"
            >
              {/* YouTube SVG Path */}
              <path
                d="M23.498 6.186a2.957 2.957 0 00-2.081-2.081C19.355 3.733 12 3.733 12 3.733s-7.355 0-9.417.372a2.957 2.957 0 00-2.081 2.081C0 8.248 0 12 0 12s0 3.752.502 5.814a2.957 2.957 0 002.081 2.081C4.645 20.267 12 20.267 12 20.267s7.355 0 9.417-.372a2.957 2.957 0 002.081-2.081C24 15.752 24 12 24 12s0-3.752-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"
              />
            </svg>
          </a>
          <a
            href="https://linkedin.com/yourprofile"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* LinkedIn Icon */}
            <svg
              className="w-8 h-8 fill-current text-text hover:text-secondary transition-colors duration-300"
              viewBox="0 0 24 24"
            >
              {/* LinkedIn SVG Path */}
              <path
                d="M22.23 0H1.771C.792 0 0 .771 0 1.729v20.542C0 23.229.792 24 1.771 24H22.23C23.207 24 24 23.229 24 22.271V1.729C24 .771 23.207 0 22.23 0zM7.07 20.452H3.542V9h3.528v11.452zM5.306 7.551a2.052 2.052 0 110-4.104 2.052 2.052 0 010 4.104zm15.146 12.901h-3.528v-5.605c0-1.336-.024-3.053-1.861-3.053-1.862 0-2.148 1.45-2.148 2.95v5.708h-3.528V9h3.391v1.561h.049c.473-.9 1.632-1.85 3.358-1.85 3.593 0 4.256 2.364 4.256 5.438v6.303z"
              />
            </svg>
          </a>
        </div>
      </section>
    </div>
  );
}

export default ContactUs;
