// src/components/ScrollImageSequence.js

import React, { useRef, useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { preloadImages, preloadFlyingImages } from '../utils/preloadImages';
import '../styles/ScrollImageSequence.css'; // Ensure correct path

// Register the ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

// Original 4 flying image names
const originalFlyingImageNames = ['bear', 'knight', 'ship', 'witch'];

// Duplicate each image 4 times to make 16
const flyingImageNames = [];
for (let i = 0; i < 4; i++) {
  flyingImageNames.push(...originalFlyingImageNames);
}

// Helper function to capitalize first letter
const capitalizeFirstLetter = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const ScrollImageSequence = () => {
  const canvasRef = useRef(null); // Reference to the canvas element
  const containerRef = useRef(null); // Reference to the container
  const [images, setImages] = useState([]); // State for frame images
  const [flyingImages, setFlyingImages] = useState([]); // State for flying images
  const [isLoading, setIsLoading] = useState(true); // Loading state

  // Preload images on component mount
  useEffect(() => {
    const frameCount = 123;
    const framesPath = '/videos/artemis_animation';
    const framesPrefix = 'frame';
    const framesExtension = 'jpg';

    const flyingPath = '/images/artemis_animation_img';
    const flyingExtension = 'png';

    // Preload frame images
    const preloadFramesPromise = preloadImages(frameCount, framesPath, framesPrefix, framesExtension);

    // Preload flying images
    const preloadFlysPromise = preloadFlyingImages(flyingImageNames, flyingPath, flyingExtension);

    // Wait for all images to load
    Promise.all([preloadFramesPromise, preloadFlysPromise])
      .then(([loadedFrames, loadedFlys]) => {
        setImages(loadedFrames);
        setFlyingImages(loadedFlys);
        setIsLoading(false);
        console.log(`Loaded ${loadedFrames.length} frame images.`);
        console.log(`Loaded ${loadedFlys.length} flying images.`);
      })
      .catch((error) => {
        console.error('Error preloading images:', error);
        setIsLoading(false);
      });
  }, []); // Empty dependency array to run once

  // Draw initial frame and handle canvas resizing
  useEffect(() => {
    if (images.length === 0) return; // Exit if images not loaded

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    const setCanvasDimensions = () => {
      const container = containerRef.current;
      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;

      // Calculate maximum width and height maintaining aspect ratio
      const aspectRatio = 16 / 9; // Adjust based on your images
      let width = containerWidth * 0.8; // 80% of container width
      let height = width / aspectRatio;

      if (height > containerHeight * 0.8) { // 80% of container height
        height = containerHeight * 0.8;
        width = height * aspectRatio;
      }

      canvas.width = width;
      canvas.height = height;

      // Redraw first frame after resizing
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(images[0], 0, 0, canvas.width, canvas.height);
      console.log(`Canvas resized: width=${width}, height=${height}`);
    };

    setCanvasDimensions();

    // Draw first frame initially
    context.drawImage(images[0], 0, 0, canvas.width, canvas.height);
    console.log('Initial frame drawn.');

    // Handle window resize
    const handleResize = () => {
      setCanvasDimensions();
    };

    window.addEventListener('resize', handleResize);
    console.log('Added window resize listener.');

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      console.log('Cleaned up window resize listener.');
    };
  }, [images]); // Only run when images are loaded

  // Synchronize Main Animation and Flying Images
  useEffect(() => {
    if (images.length === 0 || flyingImages.length === 0) return; // Exit if images not loaded

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // Define main timeline with ScrollTrigger
    const mainTimelineDuration = 10; // Fixed duration in seconds
    const mainTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: 'top top',
        end: '+=300%', // Adjust based on desired scroll length
        scrub: true,
        pin: true,
        markers: false,
      },
      onUpdate: function() {
        const scrollProgress = this.progress();
        const frameIndex = Math.min(
          Math.floor(scrollProgress * (images.length - 1)),
          images.length - 1
        );
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(images[frameIndex], 0, 0, canvas.width, canvas.height);
      },
    });

    // Dummy tween to set timeline duration
    mainTimeline.to({}, { duration: mainTimelineDuration });

    // Define transformation range
    const transformStartFrame = 20;
    const transformEndFrame = 65;
    const totalFrames = images.length;
    const transformStartTime = ((transformStartFrame - 1) / totalFrames) * mainTimelineDuration; // ≈1.54s
    const transformEndTime = ((transformEndFrame - 1) / totalFrames) * mainTimelineDuration; // ≈5.21s
    const transformDuration = transformEndTime - transformStartTime; // ≈3.67s

    // Assign unique angles (evenly distributed)
    const totalImages = flyingImages.length;
    const angleIncrement = 360 / totalImages;
    const angles = Array.from({ length: totalImages }, (_, i) => i * angleIncrement);

    // Shuffle the order for randomized animation sequence
    const shuffledIndices = gsap.utils.shuffle([...Array(totalImages).keys()]);

    // Calculate stagger based on transformDuration and number of images
    const transformTweenDuration = 1.1; // Duration of each image's transformation
    const totalStaggerTime = transformDuration - transformTweenDuration; // Time available for staggering
    const stagger = totalStaggerTime / (totalImages - 1); // ≈0.23s

    // Add flying images' transformations to the main timeline
    shuffledIndices.forEach((index) => {
      const angle = angles[index];
      const radians = angle * (Math.PI / 180);
      const distance = Math.min(window.innerWidth, window.innerHeight) * 0.9; // Adjust as needed

      const destinationX = Math.cos(radians) * distance;
      const destinationY = Math.sin(radians) * distance;

      // Calculate the exact time each image's transformation should start
      const startTime = transformStartTime + index * stagger;

      // Add the transformation tween at the calculated start time
      mainTimeline.fromTo(
        `.flying-image-${index}`,
        {
          x: 0,
          y: 0,
          scale: 0, // Start at 0 scale
          rotationY: 0,
          opacity: 1, // Fully visible at start
        },
        {
          x: destinationX,
          y: destinationY,
          scale: 1, // Scale up to original size
          rotationY: 180, // Flip horizontally
          opacity: 0, // Fade out after transformation
          ease: 'power2.out',
          duration: transformTweenDuration, // Rapid scaling
        },
        startTime // Position in the timeline (in seconds)
      );
    });

    console.log('Main timeline with flying images transformations set up.');

    // Cleanup on unmount
    return () => {
      mainTimeline.kill();
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
      console.log('Cleaned up timelines and ScrollTriggers.');
    };
  }, [images, flyingImages]); // Run when both images and flyingImages are loaded

  return (
    <div ref={containerRef} id="hero-section" className="scroll-sequence-container">
      {isLoading ? (
        <div className="loading-indicator">Loading...</div> // Customize as needed
      ) : (
        <>
          <canvas
            ref={canvasRef}
            className="scroll-sequence-canvas"
            aria-label="Scroll-controlled animation showcasing Studio Artemis"
          ></canvas>
          {/* Flying Images */}
          {flyingImages.map((img, index) => (
            <img
              key={index}
              src={img.src}
              alt={`Flying ${capitalizeFirstLetter(flyingImageNames[index])}`} // Meaningful alt text
              className={`flying-image flying-image-${index}`}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default ScrollImageSequence;
