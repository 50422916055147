// src/components/FilmModal.js

function FilmModal({ film, closeModal }) {
    const { title, poster, description, year, director, genre, boxOffice } = film;
  
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
        <div
          className="bg-background text-text rounded-lg p-8 relative w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 mx-4"
          style={{ maxWidth: '70%' }}
        >
          <button
            className="absolute top-4 right-4 text-text text-6xl hover:text-third"
            onClick={closeModal}
          >
            &times;
          </button>
          <div className="flex flex-col md:flex-row">
            <img src={poster} alt={title} className="w-full md:w-1/3 rounded-lg mb-4 md:mb-0 md:mr-6" />
            <div className="md:w-2/3">
              <h2 className="font-header text-3xl md:text-4xl font-bold mb-4">
                {title}
              </h2>
              <p className="font-body mb-2">
                <strong>Release:</strong> {year}
              </p>
              <p className="font-body mb-2">
                <strong>Genre:</strong> {genre}
              </p>
              <p className="font-body mb-2">
                <strong>Box Office:</strong> {boxOffice}
              </p>
              <p className="font-body mb-2">
                <strong>Director:</strong> {director}
              </p>
              <p className="font-body mt-4">{description}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default FilmModal;
  