// src/components/TeamCard.js

import React, { useState } from 'react';

function TeamCard({ member }) {
  const { name, position, bio, image, smallCard } = member;
  const [transform, setTransform] = useState('');

  const handleMouseMove = (e) => {
    const card = e.currentTarget;
    const rect = card.getBoundingClientRect();

    // Calculate the center of the card
    const cardX = rect.left + rect.width / 2;
    const cardY = rect.top + rect.height / 2;

    // Calculate the mouse position relative to the center of the card
    const mouseX = e.clientX - cardX;
    const mouseY = e.clientY - cardY;

    // Adjust these values to increase the intensity
    const rotateX = (mouseY / rect.height) * -60; // Increased from -15 to -30
    const rotateY = (mouseX / rect.width) * 40; // Increased from 15 to 30

    setTransform(`rotateX(${rotateX}deg) rotateY(${rotateY}deg)`);
  };

  const handleMouseLeave = () => {
    setTransform('rotateX(0deg) rotateY(0deg)');
  };

  return (
    <div
      className={`relative bg-primary rounded-lg p-6 transition-transform duration-200 ease-out team-card`}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={{
        transform: transform,
        transformStyle: 'preserve-3d',
      }}
    >
      <div className="flex items-center">
        <img
          src={image}
          alt={name}
          className={`rounded-full mr-4 ${smallCard ? 'w-16 h-16' : 'w-24 h-24'}`}
          loading='lazy'
        />
        <div>
          <h3 className="font-subheader text-xl font-bold">{name}</h3>
          <p className="font-body text-sm text-secondary">{position}</p>
        </div>
      </div>
      {!smallCard && bio && <p className="font-body text-base mt-4">{bio}</p>}
    </div>
  );
}

export default TeamCard;
