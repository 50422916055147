import React from 'react';
import Modal from 'react-modal';
import defaultImage from '../assets/images/currentWorks/default.png';

Modal.setAppElement('#root'); // Accessibility requirement

const CWMovieModal = ({ isOpen, onRequestClose, movie }) => {
  if (!movie) return null;

  const {
    title,
    productionCountry,
    director,
    producer,
    writer,
    releaseDate,
    platform,
    genre,
    description,
    image,
  } = movie;

  // Dynamically import the image
  let imageSrc;
  try {
    imageSrc = require(`../assets/images/currentWorks/${image}`);
  } catch (err) {
    imageSrc = defaultImage;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Movie Details"
      className="mx-auto my-10 max-w-3xl bg-background text-text rounded-lg shadow-xl outline-none relative overflow-y-auto max-h-full"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
    >
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 relative">
          <div className="relative pb-[150%]">
            <img
              src={imageSrc}
              alt={title}
              className="absolute inset-0 w-full h-full object-cover"
              loading='lazy'
            />
          </div>
        </div>
        <div className="p-6 md:w-1/2 relative">
          <button
            onClick={onRequestClose}
            className="absolute top-2 right-2 text-2xl font-bold text-text hover:text-secondary z-10"
            aria-label="Close Modal"
          >
            &times;
          </button>
          <h2 className="font-header text-3xl font-bold mb-4">{title}</h2>
          <p className="font-body text-lg mb-2">
            <strong>Production:</strong> {productionCountry}
          </p>
          <p className="font-body text-lg mb-2">
            <strong>Director:</strong> {director}
          </p>
          <p className="font-body text-lg mb-2">
            <strong>Producer:</strong> {producer}
          </p>
          <p className="font-body text-lg mb-2">
            <strong>Writer:</strong> {writer}
          </p>
          <p className="font-body text-lg mb-2">
            <strong>Release Date:</strong>{' '}
            {new Date(releaseDate).toLocaleString('default', { month: 'short', year: 'numeric' })}
          </p>
          <p className="font-body text-lg mb-2">
            <strong>Platform:</strong> {platform}
          </p>
          <p className="font-body text-lg mb-4">
            <strong>Genre:</strong> {genre}
          </p>
          <p className="font-body text-base">{description}</p>
        </div>
      </div>
    </Modal>
  );
};

export default CWMovieModal;
