// src/components/FilmographySection.js

import React, { useState } from 'react';
import FilmCard from './FilmCard';
import FilmModal from './FilmModal';

function FilmographySection() {
  const [selectedFilm, setSelectedFilm] = useState(null);

  const films = [
    {
        title: 'The King and the Clown (왕의남자)',
        poster: 'https://web-cf-image.cjenm.com/crop/660x950/public/share/metamng/programs/kingandtheclown-movie-poster-ko-001-27.jpg?v=1715823570',
        description: 'One of Korea’s first global box office hits grossing USD $74.5 million.',
        year: 2005,
        director: 'Lee Joon-ik',
        genre: 'Historical, Drama',
        boxOffice: '$74.5 million',
      },
    {
        title: 'My Love (你的婚礼)',
        poster: 'https://m.media-amazon.com/images/M/MV5BMTA3OTkxMDktNTE2YS00M2Q3LTkwMDMtOTBhOGRhYzE5ZjY3XkEyXkFqcGc@._V1_.jpg',
        description: 'A successful film in China that was an adaptation of the Korean film "너의결혼식".',
        year: 2021,
        director: 'Zhou Nan',
        genre: 'Romance, Drama',
        boxOffice: '$116 million',
      },
      {
        title: 'The Flu (감기)',
        poster: 'https://pbs.twimg.com/media/Cl1sAIZWEAAO-JF.jpg',
        description: 'A blockbuster film backed by CJ Entertainment.',
        year: 2013,
        director: 'Kim Sung-Su',
        genre: 'Disaster, Drama, Thriller',
        boxOffice: '$19.7 million',
      },
    // Add more films as needed
  ];

  const openModal = (film) => {
    setSelectedFilm(film);
  };

  const closeModal = () => {
    setSelectedFilm(null);
  };

  return (
    <section className="container mx-auto px-4 py-12">
      <h2 className="font-header text-3xl md:text-5xl font-bold mb-8">
        Filmography
      </h2>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {films.map((film, index) => (
          <FilmCard key={index} film={film} openModal={openModal} />
        ))}
      </div>
      {selectedFilm && <FilmModal film={selectedFilm} closeModal={closeModal} />}
    </section>
  );
}

export default FilmographySection;
