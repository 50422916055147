// src/pages/TermsOfService.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

function TermsOfService() {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen bg-black-100 p-4">
      <div className="max-w-4xl mx-auto bg-black p-8 shadow-md">
        <h1 className="text-2xl font-bold mb-4">{t('termsOfService')}</h1>
        <p className="mb-4">
          {t('termsOfServiceIntro')}
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">{t('useOfService')}</h2>
        <p className="mb-4">
          {t('useOfServiceDetails')}
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">{t('userResponsibilities')}</h2>
        <p className="mb-4">
          {t('userResponsibilitiesDetails')}
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">{t('intellectualProperty')}</h2>
        <p className="mb-4">
          {t('intellectualPropertyDetails')}
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">{t('termination')}</h2>
        <p className="mb-4">
          {t('terminationDetails')}
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">{t('limitationOfLiability')}</h2>
        <p className="mb-4">
          {t('limitationOfLiabilityDetails')}
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">{t('changesToTerms')}</h2>
        <p className="mb-4">
          {t('changesToTermsDetails')}
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">{t('contactUs')}</h2>
        <p className="mb-4">
          {t('contactUsDetails')}
        </p>
      </div>
    </div>
  );
}

export default TermsOfService;
