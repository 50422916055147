// src/pages/About.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TeamSection from '../components/TeamSection';
import FilmographySection from '../components/FilmographySection';
import MovieModal from '../components/MovieModal';
import { useInView } from 'react-intersection-observer';
import ipMNG from '../assets/images/ipmng.png';

function About() {
  const [showMovieModal, setShowMovieModal] = useState(false);

  // Intersection Observer refs
  const [aboutRef, aboutInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [whatWeDoRef, whatWeDoInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [teamRef, teamInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [filmographyRef, filmographyInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  
  const services = [
    {
      title: 'Movie & OTT Production',
      description:
        'Focusing on cross-cultural stories in Asia, we’re developing unique IPs for both theatrical and OTT release.',
      image: 'https://www.atlanticstudios.co.za/wp-content/uploads/2017/11/d0ef07_4fa1de2c54cd41fbb3eb1eb293786931_mv2-1.png', // Placeholder image
      action: () => setShowMovieModal(true),
    },
    {
      title: 'IP Management & Investment',
      description:
        'We invest in unique IPs that have strong potential to disrupt markets. We also manage various licensing and rights for further distribution and remakes.',
      image: ipMNG,
      link: '/ip-management',
    },
    {
      title: 'Agency Services',
      description:
        'Partnering with Asimula Interactive Entertainment, we provide various entertainment agency-related services from film, music, and more.',
      image: 'https://kpoplife.com/wp-content/uploads/2023/03/kpop-life-aespa-twitter.jpg',
      link: '/other-services',
    },
  ];

  return (
    <div className="bg-background text-text">
      {/* About Us Section */}
      <section
        ref={aboutRef}
        className={`container mx-auto px-4 py-12 transition-opacity duration-1000 ${
          aboutInView ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <h1 className="font-header text-4xl md:text-6xl font-bold mb-4">
          About Studio Artemis
        </h1>
        <h2 className="font-subheader text-xl md:text-2xl mb-8">
          Global Production House & IP Management
        </h2>
        <p className="font-body text-lg md:text-xl leading-relaxed mb-8">
        Founded in Seoul, South Korea in 2024, Studio Artemis is a global production house and IP management company developing and exploring new and unique content IPs within Korea and Indonesia. We are committed to providing our creatives with resources and an infinite safe space for their imagination so they can perfect their craft and bring their ideas to life.
        </p>
      </section>
      {/* What We Do Section */}
      <section
        ref={whatWeDoRef}
        className={`container mx-auto px-4 py-12 transition-opacity duration-1000 ${
          whatWeDoInView ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <h2 className="font-header text-3xl md:text-5xl font-bold mb-8">What We Do</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="relative cursor-pointer group overflow-hidden h-64"
            >
              {service.link ? (
                <Link to={service.link} className="block h-full">
                  {renderServiceCard(service)}
                </Link>
              ) : (
                <div onClick={service.action} className="block h-full">
                  {renderServiceCard(service)}
                </div>
              )}
            </div>
          ))}
        </div>
      </section>

      {/* Movie Modal */}
      {showMovieModal && <MovieModal closeModal={() => setShowMovieModal(false)} />}

      {/* Our Team Section */}
      <section
        ref={teamRef}
        className={`transition-opacity duration-1000 ${
          teamInView ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <TeamSection />
      </section>
      {/* Filmography Section */}
      <section
        ref={filmographyRef}
        className={`transition-opacity duration-1000 ${
          filmographyInView ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <FilmographySection />
      </section>
    </div>
  );
}

function renderServiceCard(service) {
  return (
    <div className="relative w-full h-full">
      {/* Image */}
      <img
        src={service.image}
        alt={service.title}
        className="w-full h-full object-cover rounded-lg transform group-hover:scale-105 transition-transform duration-300"
      />
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-40 group-hover:bg-opacity-60 transition-opacity duration-300 rounded-lg"></div>
      {/* Title */}
      <div className="absolute bottom-0 left-0 right-0 p-4">
        <h3 className="font-subheader text-2xl font-bold text-white">{service.title}</h3>
      </div>
      {/* Description Overlay */}
      <div className="absolute inset-0 flex items-end">
        <div
          className="w-full p-4 bg-black bg-opacity-80 text-white transform translate-y-full group-hover:translate-y-0 transition-transform duration-300"
        >
          <p className="font-body text-base">{service.description}</p>
        </div>
      </div>
    </div>
  );
}

export default About;
