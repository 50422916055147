// src/pages/PrivacyPolicy.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen bg-black-100 p-4">
      <div className="max-w-4xl mx-auto bg-black p-8 shadow-md">
        <h1 className="text-2xl font-bold mb-4">{t('privacyPolicy')}</h1>
        <p className="mb-4">
          {t('privacyPolicyIntro')}
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">{t('informationCollection')}</h2>
        <p className="mb-4">
          {t('informationCollectionDetails')}
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">{t('informationUsage')}</h2>
        <p className="mb-4">
          {t('informationUsageDetails')}
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">{t('dataProtection')}</h2>
        <p className="mb-4">
          {t('dataProtectionDetails')}
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">{t('changesToPolicy')}</h2>
        <p className="mb-4">
          {t('changesToPolicyDetails')}
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-2">{t('contactUs')}</h2>
        <p className="mb-4">
          {t('contactUsDetails')}
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
