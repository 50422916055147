import React from 'react';
import MovieCard from '../components/MovieCard';
import currentWorksData from '../assets/data/currentWorks.json';

const CurrentWorks = () => {
  // Group movies by release year
  const moviesByYear = currentWorksData.reduce((acc, movie) => {
    const year = new Date(movie.releaseDate).getFullYear();
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(movie);
    return acc;
  }, {});

  // Get sorted years
  const sortedYears = Object.keys(moviesByYear).sort();

  return (
    <div className="bg-background text-text">
      <section className="container mx-auto px-4 py-12">
        <h1 className="font-header text-4xl md:text-6xl font-bold mb-8 text-center">
          Current Works
        </h1>
        {sortedYears.map((year) => (
          <div key={year} className="mb-12">
            <h2 className="font-subheader text-3xl md:text-4xl font-bold mb-6">
              Coming in {year}
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
              {moviesByYear[year].map((movie, index) => (
                <MovieCard key={index} movie={movie} />
              ))}
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default CurrentWorks;
