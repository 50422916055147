// src/pages/Home.js

import React from 'react';
import { useTranslation } from 'react-i18next';
import ScrollImageSequence from '../components/ScrollImageSequence';
import { useInView } from 'react-intersection-observer';
import '../styles/glitch.css'; // Ensure glitch.css is imported

function Home() {
  const { t } = useTranslation();

  // Intersection Observer refs and inView states
  const [ageRef, ageInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [welcomeRef, welcomeInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [visionRef, visionInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [quoteRef, quoteInView] = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <div>
      {/* Scroll Video Hero Component */}
      <ScrollImageSequence />

      {/* The Age of Imagination 3.0 Section */}
      <div
        ref={ageRef}
        className={`w-full px-4 py-8 mx-auto transition-opacity duration-1000 ${ageInView ? 'opacity-100' : 'opacity-0'}`}
        style={{ maxWidth: '90%' }}
      >
        <h1
          className="font-header font-bold leading-tight text-center glitch-text text-[2.5rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem] xl:text-[6rem]"
          data-text="The Age of New Imagination 3.0"
        >
          The Age of New Imagination 3.0
        </h1>
      </div>

      {/* Welcome Section */}
      <div
        ref={welcomeRef}
        className={`w-full px-4 py-8 mx-auto transition-opacity duration-1000 ${welcomeInView ? 'opacity-100' : 'opacity-0'}`}
        style={{ maxWidth: '60%' }}
      >
        <div className="flex flex-col md:flex-row items-center md:items-start">
          {/* Left Column */}
          <div className="md:w-1/2 w-full md:pr-8 mb-8 md:mb-0">
            <h2 className="font-header font-bold leading-tight text-[2rem] sm:text-[2.5rem] md:text-[3rem] lg:text-[3.5rem] xl:text-[4rem]">
              {t('welcome')}
            </h2>
          </div>
          {/* Right Column */}
          <div className="md:w-1/2 w-full md:pl-8">
            <p className="font-body text-[1rem] sm:text-[1.125rem] md:text-[1.25rem] lg:text-[1.5rem] xl:text-[1.75rem]">
              {t('homeDescription')}
              <br /><br />
              <a
                href="/about"
                className="about-link hover:text-secondary flex items-center text-[1rem] sm:text-[1.125rem] md:text-[1.25rem] italic"
              >
                Read More..
                {/* Arrow Icon */}
                <svg
                  className="w-5 h-5 ml-2 transform transition-transform duration-300"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M22 12l-20 12 5-12-5-12z" />
                </svg>
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* Our Vision Section */}
      <div
        ref={visionRef}
        className={`w-full px-4 py-8 mx-auto transition-opacity duration-1000 ${visionInView ? 'opacity-100' : 'opacity-0'}`}
        style={{ maxWidth: '70%' }}
      >
        <div className="flex flex-col md:flex-row items-center md:items-start">
          {/* Left Column (Image) */}
          <div className="md:w-1/2 w-full md:pr-8 mb-8 md:mb-0">
            <img
              src={require('../assets/images/vision-map.png')}
              alt="Global Map Highlighting Countries"
              className="w-full h-auto"
            />
          </div>
          {/* Right Column (Text) */}
          <div className="md:w-1/2 w-full md:pl-8">
            <h2 className="font-header font-bold leading-tight text-[2rem] sm:text-[2.5rem] md:text-[3rem] lg:text-[3.5rem] xl:text-[4rem] mb-4">
              OUR VISION
            </h2>
            <p className="font-body text-[1rem] sm:text-[1.125rem] md:text-[1.25rem] lg:text-[1.5rem] xl:text-[1.75rem]">
              Our vision is to further advance the creative industry by engaging in cross-border initiatives disrupting industries in entertainment, media, and technology throughout the globe.
            </p>
          </div>
        </div>
      </div>
      {/* quote section */}
      <div
        ref={quoteRef}
        className={`w-full px-4 py-8 mx-auto transition-opacity duration-1000 ${
          quoteInView ? 'opacity-100' : 'opacity-0'
        }`}
      >
        {/* Centered Quote */}
        <h2 className="font-subheader italic text-center text-[1.5rem] sm:text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.5rem] mb-8">
          “...Throw me to the wolves! And I’ll return leading the pack..!”
        </h2>

        {/* Columns Below the Quote */}
        <div className="w-full mx-auto" style={{ maxWidth: '70%' }}>
          <div className="flex flex-col md:flex-row items-center md:items-start">
            {/* Left Column */}
            <div className="md:w-1/2 w-full md:pr-8 mb-8 md:mb-0">
              <h3 className="font-header font-bold mb-4 text-[1.75rem] sm:text-[2rem] md:text-[2.25rem] lg:text-[2.5rem]">
                Unleash Creativity.
              </h3>
              <p className="font-body text-[1rem] sm:text-[1.125rem] md:text-[1.25rem] lg:text-[1.5rem]">
                In traditional Greek mythology, Artemis is described as fierce, relentless, and determined. Fixed on her
                goals, she adventures her own path ignoring any outside noise.
                <br />
                <br />
                We are committed to providing our creatives with resources and infinite safe-space for their imagination
                so they can perfect their craft and engineer their ideas to life regardless of if they are following the
                'trend'!
              </p>
            </div>
            {/* Right Column (Image with Outer Glow) */}
            <div className="md:w-1/2 w-full md:pl-8">
              <div className="relative">
                <img
                  src={require('../assets/images/artemis_img.png')}
                  alt="Bruce Lee"
                  className="w-full h-auto outer-glow-image"
                  loading='lazy'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;