// src/components/Footer.jsx

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../assets/artemis_logo_new.png'; // Corrected import path

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="bg-primary text-white py-6 w-full">
      <div className="mx-auto px-4 flex flex-col md:flex-row justify-center items-center">
        {/* Left Side: Logo */}
        <div className="flex-shrink-0 mb-6 md:mb-0 mr-8"> {/* Added mr-8 for padding right */}
          <Link to="/">
            <img
              src={logo}
              alt="Studio Artemis Logo"
              className="h-28 md:h-36 w-auto object-contain transform hover:scale-125 transition-transform duration-200" // Adjust h-16 and md:h-24 to change size
            />
          </Link>
        </div>

        {/* Right Side: Links and Contact */}
        <div className="flex flex-col md:flex-row md:space-x-12 space-y-6 md:space-y-0 text-left">
          {/* Quick Links */}
          <div>
            <h3 className="text-sm font-semibold mb-2">{t('quickLinks')}</h3>
            <ul className="space-y-1 text-xs text-gray-300">
              <li>
                <Link to="/" className="hover:text-secondary transition">
                  {t('home')}
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:text-secondary transition">
                  {t('about')}
                </Link>
              </li>
              <li>
                <Link to="/current-works" className="hover:text-secondary transition">
                  {t('currentWorks')}
                </Link>
              </li>
              <li>
                <Link to="/ip-management" className="hover:text-secondary transition">
                  {t('ipManagement')}
                </Link>
              </li>
              <li>
                <Link to="/other-services" className="hover:text-secondary transition">
                  {t('otherServices')}
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Information */}
          <div>
            <h3 className="text-sm font-semibold mb-2">{t('contactUs')}</h3>
            <ul className="space-y-1 text-xs text-gray-300">
              <li>
                <span className="font-medium">(주) 스튜디오아르테미스코리아 | Studio Artemis Korea Co., Ltd.</span>
              </li>
              <li>
                <span className="font-medium">Company Registration:</span>
                <span> 254-87-03492</span>
              </li>
              <li>
                <span className="font-medium">{t('address')}:</span>
                <span> 23, Samseongyo-ro 18-gil, Seongbuk-gu, Seoul, Korea, 136-043</span>
              </li>
              <li>
                <Link to="/contact-us" className="hover:text-secondary transition">
                <span className="font-medium">{t('email')}: </span>
                  contact@studioartemis.net
                </Link>
              </li>
            </ul>
            {/* Social Media Icons */}
            <div className="flex space-x-4 mt-4">
              {/* Instagram */}
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-secondary transition"
                aria-label="Instagram"
              >
                <svg className="w-6 h-6 fill-current transform hover:scale-125 transition-transform duration-200" viewBox="0 0 24 24">
                  <path d="M7.75 2h8.5A5.752 5.752 0 0122 7.75v8.5A5.752 5.752 0 0116.25 22h-8.5A5.752 5.752 0 012 16.25v-8.5A5.752 5.752 0 017.75 2zm0 1.5A4.252 4.252 0 003.5 7.75v8.5A4.252 4.252 0 007.75 20.5h8.5A4.252 4.252 0 0120.5 16.25v-8.5A4.252 4.252 0 0116.25 3.5h-8.5zM12 7a5 5 0 100 10 5 5 0 000-10zm0 1.5a3.5 3.5 0 110 7 3.5 3.5 0 010-7zm5.5-.875a1.125 1.125 0 11-2.25 0 1.125 1.125 0 012.25 0z" />
                </svg>
              </a>

              {/* YouTube */}
              <a
                href="https://www.youtube.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-secondary transition"
                aria-label="YouTube"
              >
                <svg className="w-6 h-6 fill-current transform hover:scale-125 transition-transform duration-200" viewBox="0 0 24 24">
                  <path d="M19.615 3.184C18.404 3 12 3 12 3s-6.404 0-7.615.184C3.805 3.368 2 4.583 2 6.167v11.666C2 19.417 3.805 20.632 4.385 20.816c1.211.184 7.615.184 7.615.184s6.404 0 7.615-.184C20.195 20.632 22 19.417 22 17.833V6.167c0-1.584-1.805-2.799-2.385-2.983zM9.545 15.568V8.432l6.818 3.568-6.818 3.568z" />
                </svg>
              </a>

              {/* LinkedIn */}
              <a
                href="https://www.linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-secondary transition"
                aria-label="LinkedIn"
              >
                <svg className="w-6 h-6 fill-current transform hover:scale-125 transition-transform duration-200" viewBox="0 0 24 24">
                  <path d="M4.98 3.5C4.98 4.88 3.88 6 2.5 6S0 4.88 0 3.5 1.11 1 2.5 1 4.98 2.12 4.98 3.5zM0 24V7h5v17H0zm7.5 0V7h4.75v3.27h.07c.66-1.25 2.28-2.57 4.7-2.57 5.01 0 5.94 3.3 5.94 7.58V24h-5v-7.43c0-1.78-.03-4.07-2.48-4.07-2.48 0-2.86 1.94-2.86 3.94V24H7.5z" />
                </svg>
              </a>
            </div>
          </div>

          {/* Legal Links */}
          <div>
            <h3 className="text-sm font-semibold mb-2">{t('legal')}</h3>
            <ul className="space-y-1 text-xs text-gray-300">
              <li>
                <Link to="/privacy-policy" className="hover:text-secondary transition">
                  {t('privacyPolicy')}
                </Link>
              </li>
              <li>
                <Link to="/terms-of-service" className="hover:text-secondary transition">
                  {t('termsOfService')}
                </Link>
              </li>
            </ul>
            {/* Copyright */}
            <div className="mt-4">
              <p className="text-xs">&copy; {new Date().getFullYear()} Studio Artemis. {t('allRightsReserved')}</p>
            </div>
          </div>
        </div>
      </div>
      </footer>
    );
  }

  export default Footer;
