// src/components/MovieModal.js

import React from 'react';
import { Link } from 'react-router-dom';

function MovieModal({ closeModal }) {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
      <div
        className="bg-background text-text rounded-lg p-8 relative w-11/12 md:w-3/4 lg:w-2/3 mx-4 my-8 max-h-screen overflow-y-auto"
        style={{ maxWidth: '70%' }}
      >
        <button
          className="absolute top-4 right-4 text-text text-6xl hover:text-third"
          onClick={closeModal}
        >
          &times;
        </button>
        <div className="flex flex-col md:flex-row md:items-center">
          {/* Image Container */}
          <div className="w-full md:w-1/2 flex-shrink-0 flex justify-center md:justify-start">
            <img
              src="https://blog.frame.io/wp-content/uploads/2021/09/Virtual_production_essentials.jpg"
              alt="Movie & OTT Production"
              className="w-full h-auto rounded-lg mb-4 md:mb-0"
              loading='lazy'
            />
          </div>
          {/* Text Content */}
          <div className="md:w-1/2 md:pl-6">
            <h2 className="font-header text-3xl md:text-4xl font-bold mb-4">
              Movie & OTT Production
            </h2>
            <p className="font-body mb-4 text-lg leading-relaxed">
            At Studio Artemis we combine technology and innovation with unlimited creativity. We apply big data, AI, and leading-edge visual technologies to produce the highest quality content seen to the human eye.
            </p>
            {/* Link to Current Works */}
            <Link
              to="/current-works"
              className="inline-flex items-center text-lg font-bold text-text hover:text-secondary transition-colors duration-300"
            >
              See Current Works
              <svg
                className="w-6 h-6 ml-2"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 15.707a1 1 0 010-1.414L13.586 11H4a1 1 0 110-2h9.586l-3.293-3.293a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
            <br /><br />
            <h3 className="font-subheader text-2xl font-bold mb-2">
            Unique Original Stories
            </h3>
            <p className="font-body mb-4 text-lg">
            One of the core principles at Studio Artemis is to never limit the imagination of our creatives. We love to deep dive into ideas and stories with our writers that take a unique perspective against traditional values or trends.
            </p>
            <h3 className="font-subheader text-2xl font-bold mb-2">
            International Production
            </h3>
            <p className="font-body text-lg">
            From writing full original screenplays to producing theatrical release films and OTT series, we engage with other teams and creatives in the industry to make the best content possible, whether it’s our own IP or something that’s co-developed.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MovieModal;
