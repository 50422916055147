// src/components/TeamSection.js

import React from 'react';
import TeamCard from './TeamCard';
import asim from '../assets/images/profiles/asim.png';
import daeyong from '../assets/images/profiles/daeyong.png';
import hyungkyu from '../assets/images/profiles/hyungkyu.png';
import jc from '../assets/images/profiles/jc.png';
import piljung from '../assets/images/profiles/piljung.png';
import seokhwan from '../assets/images/profiles/seokhwan.png';
import taehwan from '../assets/images/profiles/taehwan.png';
import youngju from '../assets/images/profiles/youngju.png';

function TeamSection() {
  const teamMembers = [
    {
      name: '최석환 | Choi Seok Hwan',
      position: 'Chief Writer',
      bio: 'Known for writing The King and the Clown (2005), Korea’s first global box office hit grossing USD $74.5 million and winner of the prestigious Blue Dragon Film Award.',
      image: seokhwan, // Placeholder image
      filmography: [
        // List of movies (we'll handle this in the FilmographySection)
      ],
    },
    {
      name: '김필정 | Kim Piljung',
      position: 'Chief Producer',
      bio: 'Former CEO of the Beijing Korean Film Council (KOFIC) for 10 years, spearheading Korea-China film collaborations.',
      image: piljung,
      filmography: [],
    },
    {
      name: '임영주 | Lim Youngju',
      position: 'Producer/Writer',
      bio: 'Producer and adaptation writer for the blockbuster The Flu (감기). She also wrote the screenplay for the Chinese film An Encounter to Remember (最好的相遇).',
      image: youngju,
      filmography: [],
    },
    // Management Team
    {
      name: '정대용 | Jung Dae Yong',
      position: 'CEO (KR)',
      bio: '',
      image: daeyong,
      smallCard: true,
    },
    {
      name: '아심 | Asim Kemas',
      position: 'Vice President & CEO (SG)',
      bio: '',
      image: asim,
      smallCard: true,
    },
    {
      name: '이종찬 | JC',
      position: 'President',
      bio: '',
      image: jc,
      smallCard: true,
    },
    // Music Team
    {
      name: '김형규 | Kim Hyungkyu',
      position: 'Music Program Director',
      bio: '',
      image: hyungkyu,
      smallCard: true,
    },
    {
      name: '박태환 | Park Tae Hwan',
      position: 'Music Program Manager',
      bio: '',
      image: taehwan,
      smallCard: true,
    },
  ];

  return (
    <section className="container mx-auto px-4 py-12">
      <h2 className="font-header text-3xl md:text-5xl font-bold mb-8">
        Our Team
      </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {teamMembers.map((member, index) => (
                <TeamCard key={index} member={member} />
            ))}
        </div>
    </section>
  );
}

export default TeamSection;

